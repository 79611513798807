import React, { useEffect, useCallback, useState, useMemo } from 'react';
import styled from 'styled-components';
import { styles, SdirLogo, Loader, SdirQRCode } from '@sdir/sds';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useIntl } from 'react-intl';
// eslint-disable-next-line max-len
import { CommonAddresseeServiceContractsV1TypesAttachmentType as AddresseeAttachmentType } from '@sdir/httpclient/lib/clients/core/commonaddressee';
import {
  Certificate,
  LanguageComponent,
  LanguageObject
} from '@sdir/httpclient/lib/clients/aps/personalcertificate';
import { useCertificateCodes } from '../../../services/hooks/useCertificateCodes';
import { downloadAddresseeAttachment } from '../../../helpers/addresseeAttachmentApiHelpers';
import { DisplayDateFormat } from '../../../helpers';
import { personalCertificateApi, addresseeAttachmentApi } from '../../../httpclient';

const Seafarer: React.FC = () => {
  const [photoLoading, setPhotoLoading] = useState(false);
  const [photoFile, setPhotoFile] = useState<File | null>(null);
  const [signatureLoading, setSignatureLoading] = useState(false);
  const [signatureFile, setSignatureFile] = useState<File | null>(null);
  const [currentCertificate, setCurrentCertificate] = useState<Certificate>();
  const [showBigQr, setShowBigQr] = useState<boolean>(false);

  const { legalEntityId, projectuid: uid } = useParams() as {
    legalEntityId: string;
    projectuid: string;
  };

  const intl = useIntl();

  const { result: resultCertificate, callApi: getCertificate } = useApi({
    apiFunction: UID =>
      personalCertificateApi?.v1PersonalcertificateGetpersonalcertificateUidGet(UID),
    bind: personalCertificateApi,
    callApiOnInit: false
  });

  const codes = useCertificateCodes();

  const qrData = useMemo(() => {
    if (process?.env?.REACT_APP_VERIFY_URL) {
      return `${process?.env?.REACT_APP_VERIFY_URL}/${uid}`;
    }
    return '';
  }, [uid]);

  const toggleBigQr = useCallback(() => {
    setShowBigQr(!showBigQr);
  }, [showBigQr]);

  useEffect(() => {
    if (codes && codes.result && resultCertificate && resultCertificate.certificateType) {
      const certificate = codes.result.find(
        certificates => certificates.code === resultCertificate.certificateType
      );
      if (certificate) setCurrentCertificate(certificate);
    }
  }, [codes, resultCertificate]);

  const {
    result: attachmentsResult,
    loading: attachmentsLoading,
    callApi: callAttachmentsApi
  } = useApi({
    apiFunction: () =>
      addresseeAttachmentApi.v1AttachmentGetattachmentsLegalEntityIdGet(+legalEntityId)
  });

  const getLocalizedString = useCallback(
    (langObj: LanguageObject | LanguageComponent | null | undefined) => {
      if (langObj) {
        const langKey = intl.locale === 'en' ? 'en' : 'no';
        return langObj[langKey] || '';
      }
      return '';
    },
    [intl.locale]
  );

  useEffect(() => {
    getCertificate(uid);
    callAttachmentsApi();
    setPhotoLoading(false);
    setSignatureLoading(false);
    setPhotoFile(null);
    setSignatureFile(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legalEntityId, uid]);

  useEffect(() => {
    const photos = attachmentsResult?.filter(
      x => x.attachmentType === AddresseeAttachmentType.Photo
    );
    const signatures = attachmentsResult?.filter(
      x => x.attachmentType === AddresseeAttachmentType.Signature
    );
    if (photos?.length) {
      setPhotoLoading(true);
      downloadAddresseeAttachment(legalEntityId, photos[photos.length - 1])
        .then(f => {
          setPhotoFile(f);
          setPhotoLoading(false);
        })
        .catch(() => setPhotoLoading(false));
    }
    if (signatures?.length) {
      setSignatureLoading(true);
      downloadAddresseeAttachment(legalEntityId, signatures[signatures.length - 1])
        .then(f => {
          setSignatureFile(f);
          setSignatureLoading(false);
        })
        .catch(() => setSignatureLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachmentsResult]);

  // const photoFile = useAddresseeAttachment(photoAttachment || null);
  const Remap = useCallback(
    (list: LanguageObject[] | LanguageComponent[]) => {
      const resultList: Array<string> = new Array<string>();
      list?.forEach(item => {
        if (item !== undefined) {
          resultList.push(getLocalizedString(item) || '');
        }
      });
      return resultList;
    },
    [getLocalizedString]
  );

  const getLimitationText = () => {
    if (typeof currentCertificate?.limitations === 'string') return currentCertificate.limitations;
    const langKey = intl.locale === 'en' ? 'en' : 'no';
    return currentCertificate?.limitations?.find(limitation => limitation.language === langKey)
      ?.value;
  };

  const getminimumRequirementsText = () => {
    if (typeof currentCertificate?.minimumRequirements === 'string')
      return currentCertificate.minimumRequirements;
    const langKey = intl.locale === 'en' ? 'en' : 'no';
    return currentCertificate?.minimumRequirements?.find(
      minimumRequirement => minimumRequirement.language === langKey
    )?.value;
  };

  const GeneratePositions = useCallback(() => {
    if (!resultCertificate) return null;
    const { data } = resultCertificate;
    const limits: Array<string> = (data?.limits && Remap(data.limits)) || [];
    const capacity: Array<string> = (data?.capacity && Remap(data.capacity)) || [];
    const positionList = capacity.map((c, i) => {
      return { position: c, limitations: [limits[i]] };
    });
    return positionList.map(({ position, limitations }, i) => {
      return (
        <Row columnOne={position} key={position + i}>
          {limitations.map((l, limitIndex) => (
            <p key={limitIndex + i}>{l}</p>
          ))}
        </Row>
      );
    });
  }, [resultCertificate, Remap]);

  const CapitalizeString = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const dateOfBirth = `${resultCertificate?.data?.dateOfBirth || ''}`.substring(0, 10);

  return (
    <div>
      <SeafarerContainer>
        <Title>{intl.formatMessage({ id: 'aps.certificate.title' })}</Title>
        <Wrapper>
          <CardWrapper2>
            {showBigQr && qrData && (
              <BigQr onClick={toggleBigQr}>
                <SdirQRCode size={520} data={qrData} fgColor="#004176" />
              </BigQr>
            )}
            <SdirHeader>
              <SdirLogo size="l" />
            </SdirHeader>
            {resultCertificate ? (
              <div>
                <QrCodeContainer className="displayflex">
                  <div>
                    <div>
                      <p>{getLocalizedString(resultCertificate.data?.certificateType)}</p>
                      <p>
                        {CapitalizeString(
                          getLocalizedString(resultCertificate.data?.certificateName)
                        )}
                      </p>
                    </div>
                  </div>
                  {qrData && (
                    <SmallQr onClick={toggleBigQr}>
                      <SdirQRCode size={94} data={qrData} fgColor="#004176" />
                    </SmallQr>
                  )}
                </QrCodeContainer>
                <p>{getminimumRequirementsText()}</p>
                <hr />
                <Header className="displayflex">
                  <Group>
                    <ImageWrapper>
                      {(photoLoading || attachmentsLoading) && <Loader />}
                      {photoFile && (
                        <PhotoWrapper>
                          <FileImage file={photoFile} />
                        </PhotoWrapper>
                      )}
                    </ImageWrapper>
                    <Column>
                      <PersonalInfo>
                        <PersonInfoColumn>
                          <TextField
                            title={intl.formatMessage({ id: 'aps.certificate.owner' })}
                            // eslint-disable-next-line max-len
                            description={[
                              resultCertificate.firstName,
                              resultCertificate.middleName,
                              resultCertificate.lastName
                            ]
                              .filter(Boolean)
                              .join(' ')}
                          />
                          <TextField
                            title={intl.formatMessage({ id: 'aps.certificate.nr' })}
                            description={resultCertificate.certificateNumber || ''}
                          />
                          <TextField
                            title={intl.formatMessage({ id: 'aps.certificate.valid.from' })}
                            description={moment(resultCertificate?.validFromDate).format(
                              'DD.MM.YYYY'
                            )}
                          />
                        </PersonInfoColumn>
                        <PersonInfoColumn>
                          <TextField
                            title={intl.formatMessage({ id: 'aps.certificate.birthdate' })}
                            description={DisplayDateFormat(dateOfBirth)}
                          />
                          <TextField
                            title={intl.formatMessage({ id: 'aps.certificate.valid.until' })}
                            description={moment(resultCertificate?.expiryDate).format('DD.MM.YYYY')}
                          />
                        </PersonInfoColumn>
                      </PersonalInfo>
                      {(attachmentsLoading || signatureLoading) && <Loader />}
                      {signatureFile && (
                        <SignatureWrapper>
                          <FileImage file={signatureFile} />
                        </SignatureWrapper>
                      )}
                    </Column>
                  </Group>
                </Header>
                <hr />
                <MainContent className="displayflex">
                  <p>{getLimitationText()}</p>
                  <Row
                    columnOne="STILLING/CAPACITY"
                    columnTwo="EVENTUELLE AVGRENSNINGER/LIMITATIONS APPLYING"
                    header
                  />
                  {GeneratePositions()}
                </MainContent>
              </div>
            ) : (
              <Loader />
            )}
          </CardWrapper2>
        </Wrapper>
      </SeafarerContainer>
    </div>
  );
};

export default Seafarer;

const SmallQr = styled.div``;

const QrCodeContainer = styled.div`
  display: flex;
  border-radius: 8px 8px;
  justify-content: space-between;
  height: 96px;
  padding-left: 24px;
  column-gap: 10px;
  background-color: white;
  p {
    font-size: 12px;
    margin: 0;
  }
  > div {
    display: inherit;
    align-items: center;
    /* overflow-y: auto; */
  }
  div > p:first-child {
    color: ${styles.colors.font.primary.blue5};
    font-family: ${styles.fonts.stfMedium};
    font-size: 16px;
    text-transform: uppercase;
  }
  div > p:nth-child(2) {
    color: ${styles.colors.font.primary.blue5};
    font-family: ${styles.fonts.stfSemibold};
    font-size: 24px;
    color: black;
    font-weight: bold;
  }
`;

const BigQr = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  width: 90%;
  z-index: 1;

  @media only screen and (max-width: 576px) {
    & > svg {
      width: 100%;
      height: 100%;
    }
  }
`;

const ImageWrapper = styled.div`
  padding: 20px;
`;

const Group = styled.div`
  display: flex;
  column-gap: 30;
`;

const FileImage: React.FC<{ file: File }> = ({ file }) => {
  const [imageObjectUrl, setImageObjectUrl] = useState<string>();

  useEffect(() => {
    const imageUrl = URL.createObjectURL(file);
    setImageObjectUrl(imageUrl);
    return () => URL.revokeObjectURL(imageUrl);
  }, [file]);

  return imageObjectUrl ? <img src={imageObjectUrl} alt={file.name} /> : null;
};

const SignatureWrapper = styled.div`
  height: 60px;
  width: 120px;
  padding-top: 0px;

  img {
    height: 100%;
    width: auto;
  }
`;

const PhotoWrapper = styled.div`
  border-radius: 5px;
  height: 140px;
  width: 140px;
  border: 1px solid ${({ theme }) => theme.colors.primary7};
  overflow: hidden;
  position: relative;

  img {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: auto;
    width: 100%;
  }
`;

const Row: React.FC<{
  columnOne: string;
  columnTwo?: string;
  children?: any;
  header?: boolean;
}> = ({ columnOne, columnTwo, children, header = false }) => (
  <CustomRow className="displayflex" header={header}>
    <div>
      <p>{columnOne}</p>
    </div>
    <div>{columnTwo ? <p>{columnTwo}</p> : children}</div>
  </CustomRow>
);

const CustomRow = styled.div<{ header }>`
  display: flex;
  justify-content: space-between;

  div > p {
    font-family: ${({ header }) => (header ? styles.fonts.stfBook : styles.fonts.stfSemibold)};
    font-size: ${({ header }) => (header ? '12px' : '16px')};
    margin: 0;
  }
  div:first-child {
    width: 120px;
  }
  div:not(:first-child) {
    width: 300px;
  }
`;

const MainContent = styled.div`
  flex-direction: column;
  padding: 0 25px 24px 25px;
  row-gap: 20px;
  font-family: ${styles.fonts.stfBook};
  font-size: 16px;
  * {
    column-gap: 32px;
    justify-content: flex-start;
  }
`;

const Header = styled.div`
  column-gap: 16px;
`;

const Column = styled.div`
  display: flex;
  width: 100%;
  padding-top: 12px;
  flex-direction: column;
`;

const PersonalInfo = styled.div`
  display: flex;
  column-gap: 40px;
  div:nth-child(2) {
    justify-content: flex-end;
  }
`;

const PersonInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextField: React.FC<{ title: string; description: string }> = ({ title, description }) => (
  <TextFieldStyle>
    <p>{title}</p>
    <p>{description}</p>
  </TextFieldStyle>
);

const TextFieldStyle = styled.div`
  p:first-child {
    font-family: ${styles.fonts.stfBook};
    font-size: 12px;
    margin: 0;
  }
  p + p {
    font-family: ${styles.fonts.stfSemibold};
    font-weight: bold;
    font-size: 16px;
    margin: 2px 0 16px 0;
  }
`;

const SdirHeader = styled.div`
  display: flex;
  color: ${styles.colors.primary};
  margin-bottom: 18px;
`;

const Wrapper = styled.div`
  width: 600px;
`;

const CardWrapper2 = styled.div`
  padding: 3rem;
  box-shadow: ${styles.card.boxShadow};
  border-radius: ${styles.card.borderRadius};
  background: ${styles.colors.primary10};
  color: black;
  display: flex;
  justify-content: space-between;
  position: relative;
  row-gap: 6px;
  flex-direction: column;
`;

const SeafarerContainer = styled.section`
  width: 100%;
  margin: 0 auto 0 auto;
  display: grid;
  padding: 1.5rem;
`;

const Title = styled.div`
  font-size: 3rem;
  font-weight: 700;
  margin: 5px;
  color: ${({ theme }) => theme.colors.font.PBM};
`;
