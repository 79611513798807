import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import moment from 'moment';
import { AlertShort, AlertSingleLine, DataCard, Loader, TextLink } from '@sdir/sds';
import { useCurrentUserHasAccess } from '@sdir/auth/lib/hooks';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
// eslint-disable-next-line max-len
import { CommonAddresseeServiceContractsV1TypesAttachmentType as AddresseeAttachmentType } from '@sdir/httpclient/lib/clients/core/commonaddressee';
import { HealthLimitation } from '@sdir/blueprint.aps/lib/components';
import { isToggleOn } from '@sdir/blueprint.aps/lib/helpers/permissionUtilities';
import {
  personApi,
  healthCheckApi,
  addresseeAttachmentApi,
  personReplacementApi
} from '../../httpclient';
import { ReactComponent as MergeIcon } from '../../assets/img/merge.svg';
import { DisplayDateFormat } from '../../helpers';
import { downloadAddresseeAttachment } from '../../helpers/addresseeAttachmentApiHelpers';
import { Routes } from '../Templates/Routes';
import MergeList from './PersonMerge/MergeList';
import { AppPermissions, DisableMergePersonsToggle } from '../../constants/permissions';
import { useFeatureToggles } from '../../services/hooks/useFeatureToggles';

const SeafarerCard: React.FC<{ legalEntityId: string }> = ({ legalEntityId }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [photoLoading, setPhotoLoading] = useState(false);
  const [signatureLoading, setSignatureLoading] = useState(false);
  const [photoFile, setPhotoFile] = useState<File | null>(null);
  const [signatureFile, setSignatureFile] = useState<File | null>(null);
  const mergePersonsPermission = useCurrentUserHasAccess(AppPermissions.ViewDraftAndPayment);
  const featureToggle = useFeatureToggles();
  const enablePersonMerge = !isToggleOn(featureToggle?.result, DisableMergePersonsToggle, '');

  const {
    result: personResult,
    loading: personLoading,
    error: personError,
    callApi: callPersonApi
  } = useApi({
    apiFunction: () =>
      personApi.v1PersonSearchforpersonbylegalentityidQueryPost({ query: legalEntityId })
  });

  const {
    result: healthResult,
    loading: healthLoading,
    error: healthError,
    callApi: callHealthApi,
    errorObject: healthErrorOject
  } = useApi({
    apiFunction: () => healthCheckApi.v1HealthcheckGethealthcheckLegalEntityIdGet(legalEntityId)
  });

  const {
    result: attachmentsResult,
    loading: attachmentsLoading,
    callApi: callAttachmentsApi
  } = useApi({
    apiFunction: () =>
      addresseeAttachmentApi.v1AttachmentGetattachmentsLegalEntityIdGet(+legalEntityId)
  });

  const {
    result: replacementsResult,
    loading: replacementsLoading,
    callApi: callReplacementsApi
  } = useApi({
    apiFunction: id => personReplacementApi.v1PersonreplacementGetreplacementsIdGet(id)
  });

  const currentReplacement = replacementsResult?.current;

  const onDuplicatePerson = useCallback(() => {
    navigate(generatePath(Routes.personMerge, { legalEntityId }));
  }, [navigate]);

  const onNavigateToPerson = useCallback(
    (id: string | number) => {
      const formattedId = `${id}`;
      navigate(generatePath(Routes.person, { legalEntityId: formattedId }));
    },
    [navigate]
  );

  const person = personResult?.source?.[0];

  useEffect(() => {
    callHealthApi();
    callPersonApi();
    callAttachmentsApi();
    setPhotoLoading(false);
    setSignatureLoading(false);
    setPhotoFile(null);
    setSignatureFile(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legalEntityId]);

  useEffect(() => {
    const commonAddresseeId = personResult?.source?.[0]?.commonAddresseeId;
    if (commonAddresseeId) {
      callReplacementsApi(commonAddresseeId);
    }
  }, [personResult]);

  useEffect(() => {
    const photos = attachmentsResult?.filter(
      x => x.attachmentType === AddresseeAttachmentType.Photo
    );
    const signatures = attachmentsResult?.filter(
      x => x.attachmentType === AddresseeAttachmentType.Signature
    );
    if (photos?.length) {
      setPhotoLoading(true);
      downloadAddresseeAttachment(legalEntityId, photos[photos.length - 1])
        .then(f => {
          setPhotoFile(f);
          setPhotoLoading(false);
        })
        .catch(() => setPhotoLoading(false));
    }
    if (signatures?.length) {
      setSignatureLoading(true);
      downloadAddresseeAttachment(legalEntityId, signatures[signatures.length - 1])
        .then(f => {
          setSignatureFile(f);
          setSignatureLoading(false);
        })
        .catch(() => setSignatureLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachmentsResult]);

  const { age, dob } = useMemo(() => {
    const dateOfBirth = `${person?.dateOfBirth || ''}`.substring(0, 10);
    const diff = person?.dateOfBirth ? moment().diff(moment(person.dateOfBirth), 'years') : null;
    const yearsText = intl.formatMessage({ id: 'datacard.seafarer.age' });
    return {
      age: diff ? ` (${diff || '-'} ${yearsText})` : '',
      dob: DisplayDateFormat(dateOfBirth)
    };
  }, [intl, person]);

  if (personLoading) return <Loader />;

  const address = person?.addresses?.[0];
  const apiError =
    (healthErrorOject && !axios.isAxiosError(healthErrorOject)) ||
    healthErrorOject?.response?.status !== 404;

  const showHasReplacement =
    mergePersonsPermission &&
    enablePersonMerge &&
    !replacementsLoading &&
    !personLoading &&
    `${currentReplacement?.legalEntityId}` !== legalEntityId;
  const showReplacements =
    mergePersonsPermission &&
    enablePersonMerge &&
    !replacementsLoading &&
    replacementsResult &&
    !personLoading &&
    !showHasReplacement &&
    `${currentReplacement?.legalEntityId}` === legalEntityId;
  const showPersonMergeLink = mergePersonsPermission && enablePersonMerge && !showHasReplacement;
  return (
    <>
      <DataCard title={intl.formatMessage({ id: 'datacard.seafarer.title' })}>
        <Container>
          {personLoading && <Loader />}
          {personError && <AlertSingleLine type="error" title="Error" text={personError} />}
          {personResult && !person && (
            <AlertSingleLine
              type="error"
              title="Error"
              text={intl.formatMessage({ id: 'error.noResult' })}
            />
          )}
          {person && (
            <>
              {showHasReplacement && (
                <CurrentlyActiveCustomerCard>
                  <AlertShort
                    type="error"
                    text={intl.formatMessage({ id: 'aps.person.merge.hasexisting' })}
                  />
                  <TextLink
                    text={intl.formatMessage({ id: 'aps.person.merge.gotoexisting' })}
                    onClick={() => onNavigateToPerson(currentReplacement?.legalEntityId || '')}
                  />
                </CurrentlyActiveCustomerCard>
              )}
              <MainInfo>
                {(photoLoading || attachmentsLoading) && <Loader />}
                {photoFile && (
                  <PhotoWrapper>
                    <FileImage file={photoFile} />
                  </PhotoWrapper>
                )}
                <MainText>
                  <Name>
                    {[person.firstName, person.middleName, person.lastName]
                      .filter(Boolean)
                      .join(' ')}
                  </Name>
                  {person.email && <ContactInfo>{person.email}</ContactInfo>}
                  {person.phoneNumber && <ContactInfo>{person.phoneNumber}</ContactInfo>}
                </MainText>
              </MainInfo>
              <div>
                <Header>{intl.formatMessage({ id: 'aps.customerCard.dNumber' })}</Header>
                <Info className="row">
                  <span>{person.personalIdentificationNumber}</span>
                  {showPersonMergeLink && (
                    <TextLink
                      text={intl.formatMessage({ id: 'aps.person.merge.link' })}
                      icon={<MergeIcon />}
                      onClick={onDuplicatePerson}
                    />
                  )}
                </Info>
                <Header>{intl.formatMessage({ id: 'aps.customerCard.dob' })}</Header>
                <Info>
                  {dob}
                  {age}
                </Info>
                <Header>{intl.formatMessage({ id: 'aps.customerCard.citizenship' })}</Header>
                <Info>{person.nationality}</Info>
                {address && (
                  <>
                    <Header>{intl.formatMessage({ id: 'aps.customerCard.address' })}</Header>
                    <Info>{address?.streetName}</Info>
                    <Info>
                      {address?.zipCode} {address?.city}
                    </Info>
                    <Info>{address?.country}</Info>
                  </>
                )}
              </div>
              {showReplacements && (
                <MergeList replacements={replacementsResult} onPersonClick={onNavigateToPerson} />
              )}
            </>
          )}
          {healthLoading && <Loader />}
          {healthError && apiError && (
            <AlertSingleLine
              type="error"
              title="Error"
              text={intl.formatMessage({ id: 'error.noResult' })}
            />
          )}
          {healthResult && (
            <HealthDiv>
              <Header>{intl.formatMessage({ id: 'aps.health.header' })}</Header>
              <HealthInfo>
                <HealthLimitation healthCheck={healthResult} />
              </HealthInfo>
            </HealthDiv>
          )}
        </Container>
      </DataCard>
      {(attachmentsLoading || signatureLoading) && <Loader />}
      {signatureFile && (
        <DataCard title={intl.formatMessage({ id: 'datacard.seafarer.signaturetitle' })}>
          <SignatureWrapper>
            <FileImage file={signatureFile} />
          </SignatureWrapper>
        </DataCard>
      )}
    </>
  );
};

export default SeafarerCard;

const FileImage: FC<{ file: File }> = ({ file }) => {
  const [imageObjectUrl, setImageObjectUrl] = useState<string>();

  useEffect(() => {
    const imageUrl = URL.createObjectURL(file);
    setImageObjectUrl(imageUrl);
    return () => URL.revokeObjectURL(imageUrl);
  }, [file]);

  return imageObjectUrl ? <img src={imageObjectUrl} alt={file.name} /> : null;
};

const PhotoWrapper = styled.div`
  border-radius: 80px;
  height: 80px;
  width: 80px;
  border: 1px solid ${({ theme }) => theme.colors.primary7};
  overflow: hidden;
  position: relative;

  img {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: auto;
    width: 100%;
  }
`;

const SignatureWrapper = styled.div`
  height: 112px;
  padding: 24px;

  img {
    height: 100%;
    width: auto;
  }
`;

const Container = styled.section`
  width: 100%;
  padding: 3rem 2rem 3rem 2rem;
`;

const CurrentlyActiveCustomerCard = styled.div`
  margin-bottom: 15px;

  button {
    margin-top: 10px;
  }
`;

const MainInfo = styled.div`
  display: flex;
  gap: 24px;
`;

const MainText = styled.div`
  display: block;
`;

const Name = styled.div`
  font-size: 24px;
  color: ${({ theme }) => theme.colors.font.text};
  font-family: ${({ theme }) => theme.fonts.stfSemibold};
  font-weight: normal;
  margin-bottom: 8px;
  &&& {
    line-height: 1;
  }
`;

const ContactInfo = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.font.text};
  font-family: ${({ theme }) => theme.fonts.stfBook};
  font-weight: normal;
`;

const Header = styled.div`
  font-size: 14px;
  color: #333333a6;
  font-weight: bold;
  margin-top: 15px;
`;

const Info = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.font.text};
  font-weight: bold;

  &.row {
    display: flex;
    justify-content: space-between;
  }

  button {
    font-weight: normal;
  }
`;

const HealthInfo = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.font.text};
  font-weight: bold;
`;

const HealthDiv = styled.div`
  margin-top: 3rem;
`;
