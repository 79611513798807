import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { SdirTabs, Loader } from '@sdir/sds';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { useToast } from '@sdir/utilities/lib/hooks';
import { VesselHeaderCard } from '@sdir/blueprint.et/lib/components';
import { GetProjectActivitiesOverviewV3Result } from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import ApiError from '../../../types/ApiError';
import RegulationsSection from '../../Organisms/RegulationSection';
import { projectApi } from '../../../httpclient';
import { ActivitySection } from '../../Molecules';
import { AccessSection, InspectionSection } from '../../Organisms';
import { Routes } from '../../Templates/Routes';
import {
  vesselTabOptions,
  vesselTabOptionsHiddenRequirementList
} from '../../Templates/Routes/constants';
import useRequirements from '../../../services/hooks/useRequirements';
import useCertificate from '../../../services/hooks/useCertificate';
import IncidentContainer from '../../Organisms/IncidentContainer';
import CertificateSection from '../../Organisms/CertificateSection';
import VesselCardSectionContainer from '../../Organisms/VesselCardSectionContainer';
import useETCaseworker from '../../../services/hooks/useETCaseworker';

interface VesselPageProps {
  requirementsListTabEnabled?: boolean;
  surveyTabEnabled?: boolean;
}

const VesselPage: React.FC<VesselPageProps> = ({
  requirementsListTabEnabled,
  surveyTabEnabled
}) => {
  const { tab, projectuid } = useParams() as { projectuid: string; tab: string };
  const [currentSelectedSubjectarea, setCurrentSelectedSubjectarea] = useState<string>();
  const intl = useIntl();
  const navigate = useNavigate();
  let tabOptions = requirementsListTabEnabled
    ? Object.keys(vesselTabOptions)
    : Object.keys(vesselTabOptionsHiddenRequirementList);

  if (!surveyTabEnabled) {
    tabOptions = tabOptions.filter(t => t !== 'inspection');
  }

  const getTabIndex = () => tabOptions.findIndex(el => el === tab);
  const { setError } = useToast();
  const subjectAreaList: GetProjectActivitiesOverviewV3Result = useSelector(
    (state: any) => state.appState.activitiesOverview
  );

  const {
    subjectAreaListLoading,
    activities,
    activitiesLoading,
    hideActivities,
    filteredReqList,
    filteredReqListLoading,
    getFilteredReqs,
    requirementsOverview,
    requirementsOverviewLoading,
    onMenuItemClick,
    getActivityList,
    getProjectActivitiesOverview
  } = useRequirements(projectuid);

  const { getEtCaseWorkers } = useETCaseworker();

  const etCaseWorkers = useSelector((state: any) => state.appState.etCaseWorkers);
  const etCaseWorkersLoading = useSelector((state: any) => state.appState.etCaseWorkersLoading);

  useEffect(() => {
    if (!etCaseWorkers && !etCaseWorkersLoading) {
      getEtCaseWorkers();
    }

    const autoselectSubjectarea = localStorage.getItem('newActivityCurrentSubjectarea');
    if (autoselectSubjectarea) {
      setCurrentSelectedSubjectarea(autoselectSubjectarea);
    }
  }, []);

  const {
    result: vessel,
    loading: vesselLoading,
    errorObject: vesselError,
    callApi: getVessel
  } = useApi({
    apiFunction: () => projectApi.v1ProjectGetprojectbyidProjectIdGet(projectuid),
    callApiOnInit: true,
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${intl.formatMessage({ id: 'error.load' }, { error: 'prosjekt' })}: ${err}`);
      }
    }
  });

  useEffect(() => {
    if (vessel && projectuid && vessel.uid !== projectuid) {
      getVessel();
      getProjectActivitiesOverview();
      getPlannedVesselCertificates();
      getVesselCertificates();
    }
  }, [projectuid, vessel]);

  const {
    getPlannedVesselCertificates,
    getSpecificationsOptions,
    getVesselCertificates,
    loading
  } = useCertificate(projectuid);
  const specificationOptions = useSelector(
    (state: any) => state.appState.vesselSpecificationOptions
  );
  const vesselCertificates = useSelector((state: any) => state.appState.vesselCertificates);
  const plannedCertificates = useSelector((state: any) => state.appState.plannedVesselCertificates);

  useEffect(() => {
    if (['activitylist', 'certificate', 'inspection'].includes(tab)) {
      if (!plannedCertificates) {
        (async () => {
          await getPlannedVesselCertificates();
        })();
      }
    }

    if (!specificationOptions) {
      (async () => {
        await getSpecificationsOptions();
      })();
    }

    if (['certificate'].includes(tab) && vessel && !vesselCertificates) {
      (async () => {
        await getVesselCertificates(vessel.vesselUid);
      })();
    }
  }, [tab, vessel]);

  if (vesselError) {
    throw new ApiError(vesselError.response);
  }

  const tabTitlesWithoutRequirements = surveyTabEnabled
    ? [
        intl.formatMessage({ id: 'vesselcard.tab.title' }),
        intl.formatMessage({ id: 'allekravpage.tabs.activitylist' }),
        intl.formatMessage({ id: 'allekravpage.tabs.projectmanagement' }),
        intl.formatMessage({ id: 'survey.tab.title' }),
        intl.formatMessage({ id: 'incidents.tab.title' }),
        intl.formatMessage({ id: 'certificates.tab.title' })
      ]
    : [
        intl.formatMessage({ id: 'vesselcard.tab.title' }),
        intl.formatMessage({ id: 'allekravpage.tabs.activitylist' }),
        intl.formatMessage({ id: 'allekravpage.tabs.projectmanagement' }),
        intl.formatMessage({ id: 'incidents.tab.title' }),
        intl.formatMessage({ id: 'certificates.tab.title' })
      ];

  const allTabTitles = [intl.formatMessage({ id: 'allekravpage.tabs.allrequirements' })].concat(
    tabTitlesWithoutRequirements
  );

  const onTabClick = (key: number) => {
    if (tabOptions[key] === 'activitylist') handleActivityPageReloads();
    navigate(generatePath(Routes.vesselWorkspace, { tab: tabOptions[key], projectuid }));
  };

  const handleOnMenuItemClicked = subjectarea => {
    setCurrentSelectedSubjectarea(subjectarea);
    onMenuItemClick(subjectarea);
  };

  const handleActivityPageReloads = () => {
    if (
      subjectAreaList &&
      subjectAreaList.subjectAreaDocuments &&
      subjectAreaList.subjectAreaDocuments[0] &&
      subjectAreaList.subjectAreaDocuments[0].subjectAreaUri
    ) {
      getActivityList(subjectAreaList.subjectAreaDocuments[0].subjectAreaUri);
    }
  };

  const renderChildren = () => {
    switch (tabOptions[getTabIndex()]) {
      case 'activitylist':
        return (
          <ActivitySection
            menuListLoading={subjectAreaListLoading}
            onMenuItemClick={handleOnMenuItemClicked}
            activities={activities}
            activitiesLoading={activitiesLoading || hideActivities}
            getActivityList={getActivityList}
            currentSelectedSubjectarea={currentSelectedSubjectarea}
            requirementsListTabEnabled={requirementsListTabEnabled}
          />
        );
      case 'inspection':
        return <InspectionSection etCaseWorkers={etCaseWorkers} vesselUid={vessel?.vesselUid} />;
      case 'requirementlist': {
        return (
          <RegulationsSection
            filteredRequirements={filteredReqList}
            listMenuRequirements={requirementsOverview}
            getFilteredRequirements={eliref => getFilteredReqs(eliref)}
            loading={filteredReqListLoading}
          />
        );
      }
      case 'projectmanagement':
        return <AccessSection />;
      case 'incident':
        return <IncidentContainer />;
      case 'certificate':
        return <CertificateSection loading={loading} />;
      case 'vesselcard':
        if (vessel?.vesselUid) {
          return (
            <VesselCardSectionContainer vesselUid={vessel.vesselUid} projectUid={projectuid} />
          );
        }
        return null;
      default:
        return null;
    }
  };

  if (vesselLoading || requirementsOverviewLoading)
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );

  return (
    <Container>
      {vessel && <VesselHeaderCard vessel={vessel} />}
      <TabContainer>
        <SdirTabs
          activeKey={getTabIndex()}
          titles={requirementsListTabEnabled ? allTabTitles : tabTitlesWithoutRequirements}
          eachTabClick={onTabClick}
        />
      </TabContainer>

      {renderChildren()}
    </Container>
  );
};

export default VesselPage;

const TabContainer = styled.div`
  height: 6rem;
  margin-top: 5rem;
`;

const LoaderContainer = styled.div`
  grid-area: 4 / 8;
`;

const Container = styled.section`
  width: 100%;
  max-width: 188rem;
  margin: 4rem auto 0 auto;
`;
