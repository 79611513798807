/* eslint-disable no-unused-expressions */
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { useToast } from '@sdir/utilities/lib/hooks';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { activityApi, requirementApi } from '../../httpclient';
import { SET_ACTIVITIES_OVERVIEW } from '../../store/constants/action-types';

const useRequirements = projectuid => {
  const { setError } = useToast();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [hideActivities, setHideActivities] = useState(false);

  const { scrollToId } = useParams() as { scrollToId?: string };

  const { result: requirementsOverview, loading: requirementsOverviewLoading } = useApi({
    apiFunction: () =>
      requirementApi.v3RequirementGetprojectrequirementsoverviewProjectUidGet(projectuid),
    callApiOnInit: true,
    errorCallback: err => {
      err.length > 0 &&
        setError(`${intl.formatMessage({ id: 'error.load' }, { error: 'krav' })}: ${err}`);
    }
  });

  const {
    result: filteredReqList,
    loading: filteredReqListLoading,
    callApi: getFilteredReqs
  } = useApi({
    apiFunction: (eliref = undefined) =>
      requirementApi.v3RequirementGetprojectrequirementsv3ChapterEliReferenceGet(
        projectuid,
        eliref
      ),
    callApiOnInit: true,
    errorCallback: err => {
      err.length > 0 &&
        setError(`${intl.formatMessage({ id: 'error.load' }, { error: 'krav' })}: ${err}`);
    }
  });

  const { result: activities, loading: activitiesLoading, callApi: getActivityList } = useApi({
    apiFunction: (subjectAreaUri = undefined) =>
      activityApi.v2ActivityGetprojectactivities2SubjectAreaUriGet(projectuid, subjectAreaUri),
    errorCallback: err => {
      err.length > 0 &&
        setError(`${intl.formatMessage({ id: 'error.load' }, { error: 'aktiviteter' })}: ${err}`);
    },
    successCallback: () => {
      setHideActivities(true);
      setTimeout(() => setHideActivities(false), 500);
    }
  });

  const { loading: subjectAreaListLoading, callApi: getProjectActivitiesOverview } = useApi({
    apiFunction: () => activityApi.v3ActivityGetprojectactivitiesoverviewv3ProjectIdGet(projectuid),
    callApiOnInit: true,
    errorCallback: err => {
      err.length > 0 &&
        setError(`${intl.formatMessage({ id: 'error.load' }, { error: 'krav' })}: ${err}`);
    },
    successCallback: data => {
      dispatch({ type: SET_ACTIVITIES_OVERVIEW, payload: data });
      const autoselectSubjectarea = localStorage.getItem('newActivityCurrentSubjectarea');
      if (autoselectSubjectarea) {
        getActivityList(autoselectSubjectarea);
        localStorage.removeItem('newActivityCurrentSubjectarea');
      } else if (
        !scrollToId &&
        data.subjectAreaDocuments &&
        data.subjectAreaDocuments[0] &&
        data.subjectAreaDocuments[0].subjectAreaUri
      ) {
        getActivityList(data.subjectAreaDocuments[0].subjectAreaUri);
      } else if (scrollToId && data.subjectAreaDocuments) {
        const subjectArea = data.subjectAreaDocuments.find(el =>
          el.activityOrActivityGroupOverview?.some(al => {
            if (al?.activityDetail?.uid === scrollToId) return true;
            return al?.documentActivitiesOverview?.activityOverview?.some(
              ul => ul?.uid === scrollToId
            );
          })
        );
        getActivityList(subjectArea?.subjectAreaUri);
      } else {
        getActivityList();
      }
    }
  });

  const onMenuItemClick = (subjectAreaUri: string) => {
    getActivityList(subjectAreaUri);
  };

  const workspaceData = {
    subjectAreaListLoading,
    activities,
    activitiesLoading,
    hideActivities,
    filteredReqList,
    filteredReqListLoading,
    getFilteredReqs,
    requirementsOverview,
    requirementsOverviewLoading,
    onMenuItemClick,
    getActivityList,
    getProjectActivitiesOverview
  };

  return workspaceData;
};

export default useRequirements;
