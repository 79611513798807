import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ActivityOrActivityGroup,
  GetProjectActivitiesOverviewV3Result
} from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { getStatusList } from '@sdir/blueprint.et/lib/helpers';
import {
  ScopeFilterOptions,
  TaskStatusFilterOptions,
  TaskTypeFilterOptions
} from '@sdir/blueprint.et/lib/types/enums';
import { SortingDirection } from '@sdir/utilities/lib/types/sorting';
import {
  ActiveFilters,
  Certificate,
  IntervalsAndInspectionTypes,
  defaultActiveFilters
} from '@sdir/blueprint.et/lib/components/Organisms/TaskFilters/TaskFiltersV1';
import { sortObjects } from '@sdir/utilities/lib/helpers/sorting';
import { UPDATE_ACTIVITY_STATUS_COMPLETED } from '../../store/constants/action-types';
import { CallApiResult } from '../../store/constants/types';

const useTaskFilter = (
  activities: ActivityOrActivityGroup[] | undefined,
  getActivityList?: (subjectAreaUri?: string) => Promise<CallApiResult<ActivityOrActivityGroup[]>>,
  resetSelectedActivities?: () => void
) => {
  const dispatch = useDispatch();
  const activityStatusChanged = useSelector((state: any) => state.appState.activityStatusChanged);
  const [activeFilters, setActiveFilters] = useState<ActiveFilters>(defaultActiveFilters);
  const [filteredActivities, setFilteredActivities] = useState<ActivityOrActivityGroup[]>();

  const specificationsOptions = useSelector(
    (state: any) => state.appState.vesselSpecificationOptions
  );
  const plannedVesselCertificates = useSelector(
    (state: any) => state.appState.plannedVesselCertificates
  );

  const subjectAreaList: GetProjectActivitiesOverviewV3Result = useSelector(
    (state: any) => state.appState.activitiesOverview
  );

  const availableIntervalsAndInspectionTypes: IntervalsAndInspectionTypes = useMemo(() => {
    const uniqueIntervals = new Set();
    const uniqueInspectionTypes = new Set();

    subjectAreaList?.subjectAreaDocuments?.forEach(subArea => {
      subArea.activityOrActivityGroupOverview?.forEach(overview => {
        if (overview.type === 'Activity' && overview.activityDetail) {
          overview.activityDetail.intervals?.forEach(i => uniqueIntervals.add(i));
          overview.activityDetail.inspectionTypes?.forEach(i => uniqueInspectionTypes.add(i));
        }

        if (overview.type === 'ActivityGroup' && overview.documentActivitiesOverview) {
          overview.documentActivitiesOverview.activityOverview?.forEach(ac => {
            ac.intervals?.forEach(i => uniqueIntervals.add(i));
            ac.inspectionTypes?.forEach(i => uniqueInspectionTypes.add(i));
          });
        }
      });
    });

    const intervals = Array.from(uniqueIntervals) as string[];
    const inspectionTypes = Array.from(uniqueInspectionTypes) as string[];
    return { intervals, inspectionTypes };
  }, [subjectAreaList]);

  useEffect(() => updateFilteredActivities(), [activities, activeFilters]);

  const updateFilteredActivities = () => {
    // To refresh activity list if activity status was changed by Bekreft Oppgave/Klar for inspection
    if (activityStatusChanged?.subjectAreaUri && getActivityList) {
      getActivityList(activityStatusChanged?.subjectAreaUri);
      dispatch({ type: UPDATE_ACTIVITY_STATUS_COMPLETED });
    }
    let newFilteredActivities = filterTasksByStatus();
    newFilteredActivities = filterByType(newFilteredActivities);
    newFilteredActivities = filterTasksByCertificate(newFilteredActivities);
    newFilteredActivities = filterTasksByScope(newFilteredActivities);
    newFilteredActivities = filterTaskByIntervals(newFilteredActivities);
    newFilteredActivities = filterTaskByInspectionTypes(newFilteredActivities);
    setFilteredActivities(newFilteredActivities);
  };

  const filterByType = activitiesByStatus => {
    if (!activitiesByStatus) return [];
    switch (activeFilters.taskType) {
      case TaskTypeFilterOptions.ShowAll:
        return activitiesByStatus;
      case TaskTypeFilterOptions.DocumentControl:
        return activitiesByStatus.filter(
          activity =>
            (activity.type === 'Activity' &&
              activity.activity.checkpointType.includes('DocumentControl')) ||
            (activity.type === 'ActivityGroup' &&
              activity.documentControlActivityGroup.activities.some(a =>
                a.checkpointType.includes('DocumentControl')
              ))
        );
      case TaskTypeFilterOptions.Inspection:
        return activitiesByStatus.filter(
          activity =>
            (activity.type === 'Activity' &&
              activity.activity.checkpointType.includes('Inspection')) ||
            (activity.type === 'ActivityGroup' &&
              activity.documentControlActivityGroup.activities.some(a =>
                a.checkpointType.includes('Inspection')
              ))
        );
      default:
        return activitiesByStatus;
    }
  };

  const filterTasksByStatus = () => {
    if (!activities) return [];
    if (
      activeFilters.status.length === 0 ||
      activeFilters.status.includes(TaskStatusFilterOptions.ShowAll)
    ) {
      return [...activities];
    }
    const filteredTasks: any[] = [];
    const selectedStatusList = getStatusList(activeFilters.status);
    activities?.forEach(activity => {
      if (activity.activity) {
        if (
          (activity?.activity?.lastEvent?.status &&
            selectedStatusList.includes(activity.activity.lastEvent.status)) ||
          (!activity?.activity?.lastEvent &&
            activeFilters.status.includes(TaskStatusFilterOptions.AwaitingShippingCompany))
        ) {
          filteredTasks.push(activity);
        }
      } else if (activity.documentControlActivityGroup) {
        const activitiesList =
          activity?.documentControlActivityGroup?.activities &&
          activity.documentControlActivityGroup.activities.filter(
            task =>
              (task.lastEvent?.status && selectedStatusList.includes(task.lastEvent.status)) ||
              (!task.lastEvent &&
                activeFilters.status.includes(TaskStatusFilterOptions.AwaitingShippingCompany))
          );
        if (activitiesList && activitiesList.length > 0) {
          filteredTasks.push({
            ...activity,
            documentControlActivityGroup: {
              ...activity.documentControlActivityGroup,
              activities: activitiesList,
              hiddenActivities:
                (activity.documentControlActivityGroup.activities?.length || 0) -
                activitiesList.length
            }
          });
        }
      }
    });
    return filteredTasks;
  };

  const filterTasksByCertificate = activitiesOrActivityGroups => {
    if (!activitiesOrActivityGroups) return [];
    if (
      activeFilters?.certificates?.length === 0 ||
      activeFilters.certificates.includes(TaskStatusFilterOptions.ShowAll)
    ) {
      return [...activitiesOrActivityGroups];
    }
    const filteredTasks: ActivityOrActivityGroup[] = [];
    activitiesOrActivityGroups?.forEach(activity => {
      if (activeFilters.certificates.some(ai => activity.activity?.certificates.includes(ai))) {
        filteredTasks.push(activity);
      } else if (activity.documentControlActivityGroup) {
        const activitiesList = activity.documentControlActivityGroup.activities.filter(task =>
          activeFilters.certificates.some(ai => task?.certificates.includes(ai))
        );
        if (activitiesList.length > 0) {
          filteredTasks.push({
            ...activity,
            documentControlActivityGroup: {
              ...activity.documentControlActivityGroup,
              activities: activitiesList,
              hiddenActivities:
                activity.documentControlActivityGroup.activities.length - activitiesList.length
            }
          });
        }
      }
    });
    return filteredTasks;
  };

  const filterTasksByScope = activitiesOrActivityGroups => {
    if (!activitiesOrActivityGroups) return [];
    if (!activeFilters?.scope || activeFilters.scope === ScopeFilterOptions.ShowAll) {
      return [...activitiesOrActivityGroups];
    }
    const filteredTasks: ActivityOrActivityGroup[] = [];
    activitiesOrActivityGroups?.forEach(activity => {
      if (activity.activity) {
        if (
          (activeFilters.scope === ScopeFilterOptions.Mandatory &&
            activity.activity?.scope === true) ||
          (activeFilters.scope === ScopeFilterOptions.Expanded &&
            activity.activity?.scope === false)
        ) {
          filteredTasks.push(activity);
        }
      } else if (activity.documentControlActivityGroup) {
        const activitiesList = activity.documentControlActivityGroup.activities.filter(
          task =>
            (activeFilters.scope === ScopeFilterOptions.Mandatory && task.scope === true) ||
            (activeFilters.scope === ScopeFilterOptions.Expanded && task.scope === false)
        );
        if (activitiesList.length > 0) {
          filteredTasks.push({
            ...activity,
            documentControlActivityGroup: {
              ...activity.documentControlActivityGroup,
              activities: activitiesList,
              hiddenActivities:
                activity.documentControlActivityGroup.activities.length - activitiesList.length
            }
          });
        }
      }
    });
    return filteredTasks;
  };

  const filterTaskByIntervals = activitiesOrActivityGroups => {
    if (!activitiesOrActivityGroups) return [];
    if (activeFilters?.intervals?.length === 0) {
      return [...activitiesOrActivityGroups];
    }
    const filteredTasks: ActivityOrActivityGroup[] = [];
    activitiesOrActivityGroups?.forEach(activity => {
      if (activeFilters.intervals.some(ai => activity.activity?.intervals.includes(ai))) {
        filteredTasks.push(activity);
      } else if (activity.documentControlActivityGroup) {
        const activitiesList = activity.documentControlActivityGroup.activities.filter(task =>
          activeFilters.intervals.some(ai => task?.intervals.includes(ai))
        );
        if (activitiesList.length > 0) {
          filteredTasks.push({
            ...activity,
            documentControlActivityGroup: {
              ...activity.documentControlActivityGroup,
              activities: activitiesList,
              hiddenActivities:
                activity.documentControlActivityGroup.activities.length - activitiesList.length
            }
          });
        }
      }
    });
    return filteredTasks;
  };

  const filterTaskByInspectionTypes = activitiesOrActivityGroups => {
    if (!activitiesOrActivityGroups) return [];
    if (activeFilters?.inspectionTypes?.length === 0) {
      return [...activitiesOrActivityGroups];
    }
    const filteredTasks: ActivityOrActivityGroup[] = [];
    activitiesOrActivityGroups?.forEach(activity => {
      if (
        activeFilters.inspectionTypes.some(ai => activity.activity?.inspectionTypes.includes(ai))
      ) {
        filteredTasks.push(activity);
      } else if (activity.documentControlActivityGroup) {
        const activitiesList = activity.documentControlActivityGroup.activities.filter(task =>
          activeFilters.inspectionTypes.some(ai => task?.inspectionTypes.includes(ai))
        );
        if (activitiesList.length > 0) {
          filteredTasks.push({
            ...activity,
            documentControlActivityGroup: {
              ...activity.documentControlActivityGroup,
              activities: activitiesList,
              hiddenActivities:
                activity.documentControlActivityGroup.activities.length - activitiesList.length
            }
          });
        }
      }
    });
    return filteredTasks;
  };

  const onFiltersChanged = (newActiveFilters: ActiveFilters) => {
    setActiveFilters(newActiveFilters);
    updateFilteredActivities();
  };

  const refreshFiltersAndSelectedActivities = (selectedFilters: ActiveFilters) => {
    resetSelectedActivities && resetSelectedActivities();
    setActiveFilters(selectedFilters);
  };

  const resetStatusAndCertificateFilters = () => {
    setActiveFilters({
      ...activeFilters,
      taskType: defaultActiveFilters.taskType,
      status: defaultActiveFilters.status
    });
  };

  const availableVesselCertificates: Certificate[] = useMemo(() => {
    if (plannedVesselCertificates) {
      let mainCertificates: Certificate[] = [];
      plannedVesselCertificates?.mainCertificates &&
        plannedVesselCertificates.mainCertificates
          ?.filter(cer => cer.uri && cer.isActive)
          ?.forEach(c => {
            const certificateFound = specificationsOptions?.mainCertificates?.find(cert => {
              return cert.code === c.uri;
            });
            if (certificateFound) {
              mainCertificates.push({
                name: certificateFound?.code?.split('#')[1],
                value: certificateFound?.code
              });
            }
          });
      const otherCertificates: Certificate[] = [];
      plannedVesselCertificates?.otherCertificates &&
        plannedVesselCertificates.otherCertificates
          ?.filter(cer => cer.uri && cer.isActive)
          ?.forEach(c => {
            const certificateFound = specificationsOptions?.otherCertificates?.find(cert => {
              return cert.code === c.uri;
            });
            if (certificateFound) {
              otherCertificates.push({
                name: certificateFound?.code?.split('#')[1],
                value: certificateFound?.code
              });
            }
          });
      mainCertificates = sortObjects(
        [...mainCertificates, ...otherCertificates],
        'name',
        SortingDirection.ASC
      );
      return mainCertificates;
    }
    return [];
  }, [plannedVesselCertificates, specificationsOptions]);

  const taskFilterData = {
    filteredActivities,
    activeFilters,
    onFiltersChanged,
    resetStatusAndCertificateFilters,
    refreshFiltersAndSelectedActivities,
    availableVesselCertificates,
    availableIntervalsAndInspectionTypes
  };

  return taskFilterData;
};

export default useTaskFilter;
