import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { SystemState } from '../types/index.d';
import rootReducer from './reducers/rootReducer';

export const storeFactory = (preloadedState?: SystemState) =>
  createStore(rootReducer, preloadedState, composeWithDevTools());

const defaultStore = storeFactory();

export default defaultStore;
