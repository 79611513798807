import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { DataTable, TextLink } from '@sdir/sds';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { useDataSorting } from '@sdir/utilities/lib/hooks';
import { useCurrentUserHasAccess } from '@sdir/auth/lib/hooks';
import moment from 'moment';
import { useNavigate, generatePath } from 'react-router-dom';
import { Routes } from '../../Templates/Routes';
import { GetCertificateNameByCode } from '../../../helpers';
import { useCertificateCodes } from '../../../services/hooks/useCertificateCodes';
import { AppPermissions } from '../../../constants/permissions';
import { PersonalApplicationColumns, PersonalApplicationRow } from '../../../helpers/TableHeaders';
import { getApplicationApi } from '../../../httpclient';
import TableCardContainer from '../../Atoms/TableCardContainer';

// Applications that has not been submitted by the user yet should not be visible
const hiddenStatuses = ['Draft', 'Payment'];

const getApplication = getApplicationApi?.v1GetGetapplicationsbylegalentityidQueryPost.bind(
  getApplicationApi
);

const ApplicationTableCard: React.FC<{ legalEntityId: string }> = ({ legalEntityId }) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const { result, loading, error, callApi } = useApi({
    apiFunction: getApplication,
    callApiOnInit: false
  });

  const codes = useCertificateCodes();
  const draftOrPaymentPermission = useCurrentUserHasAccess(AppPermissions.ViewDraftAndPayment);

  const tableData = useMemo(() => {
    if (!result) return [];
    const lang = intl.locale === 'nb' ? 'no' : 'en';
    const hideStatuses = !draftOrPaymentPermission?.hasAccess;
    return result
      .filter(i => {
        if (hideStatuses) {
          return !hiddenStatuses.includes(i?.applicationStatus || '');
        }
        return true;
      })
      .map<PersonalApplicationRow>(val => {
        const [code, type] = val?.certificate?.split('_') as string[];
        const certName = GetCertificateNameByCode(lang, code, codes.result) || code || '';
        const tableDataReturnValue: PersonalApplicationRow = {
          certificate: (
            <TextLink
              text={certName}
              onClick={() => {
                navigate(generatePath(Routes.application, { applicationUid: val.uid || '' }));
              }}
            />
          ),
          certificateValue: certName,
          applicationTypeSortValue: val.applicationType,
          applicationType: intl.formatMessage({ id: `app.type.${val.applicationType}` }),
          status: intl.formatMessage({ id: `app.status.${val.applicationStatus}` }),
          paidAt: moment(val?.payment?.paidDate || '').format('DD.MM.YYYY'),
          paidAtValue: moment(val?.payment?.paidDate || '')
        };
        return tableDataReturnValue;
      });
  }, [codes.result, history, intl, result, draftOrPaymentPermission]);

  const { sortedData, setSortedBy } = useDataSorting<any>(tableData, PersonalApplicationColumns);

  useEffect(() => {
    if (legalEntityId) {
      callApi({
        legalEntityId
      });
    }
  }, [callApi, legalEntityId]);

  return (
    <TableCardContainer
      title={intl.formatMessage({ id: 'datacard.searches.title' })}
      loading={codes.loading || loading}
      error={error}
      data={sortedData}
    >
      <DataTable
        columns={PersonalApplicationColumns}
        data={sortedData}
        onHeaderClick={setSortedBy}
        sortableKeys="*"
      />
    </TableCardContainer>
  );
};

export default ApplicationTableCard;
