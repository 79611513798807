import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Loader } from '@sdir/sds';
// eslint-disable-next-line max-len
import {
  ActivityOrActivityGroup,
  ActivityOrActivityGroupType,
  GetProjectActivitiesOverviewV3Result
} from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import {
  ListMenu,
  TaskFiltersV1,
  useTaskFilterSummary,
  useDynamicHeight
} from '@sdir/blueprint.et/lib/components';
import useTaskFilter from '../../services/hooks/useTaskFilter';
import { CallApiResult } from '../../store/constants/types';
import RequirementsMeatballMenuV1 from './RequirementsMeatballMenuV1';
import { ActivityList } from '../Organisms';
import { ReactComponent as NoActivitySelected } from '../../assets/img/NoActivitySelected.svg';

interface Props {
  menuListLoading: boolean;
  onMenuItemClick: (e: any) => void;
  listGridArea?: string;
  activities: ActivityOrActivityGroup[] | undefined;
  activitiesLoading: boolean;
  getActivityList: (subjectAreaUri?: string) => Promise<CallApiResult<ActivityOrActivityGroup[]>>;
  currentSelectedSubjectarea?: string;
  requirementsListTabEnabled?: boolean;
}

const ActivitySection: React.FC<Props> = ({
  menuListLoading,
  onMenuItemClick,
  activities,
  activitiesLoading,
  getActivityList,
  currentSelectedSubjectarea,
  requirementsListTabEnabled
}) => {
  const intl = useIntl();
  const { ref, height } = useDynamicHeight([activitiesLoading] as any);
  const menuList: GetProjectActivitiesOverviewV3Result = useSelector(
    (state: any) => state.appState.activitiesOverview
  );
  const [fetchingActivities, setFetchingActivities] = useState(false);
  const {
    filteredActivities,
    activeFilters,
    onFiltersChanged,
    refreshFiltersAndSelectedActivities,
    resetStatusAndCertificateFilters,
    availableVesselCertificates,
    availableIntervalsAndInspectionTypes
  } = useTaskFilter(activities, getActivityList);

  useEffect(() => {
    // This is to keep having a loader when we have multiple requests for getActivityList
    if (currentSelectedSubjectarea && activities?.length) {
      const { type } = activities[0];
      if (
        (type === ActivityOrActivityGroupType.Activity &&
          activities[0].activity?.subjectAreaUri !== currentSelectedSubjectarea) ||
        (type === ActivityOrActivityGroupType.ActivityGroup &&
          activities[0].documentControlActivityGroup?.activities?.[0]?.subjectAreaUri !==
            currentSelectedSubjectarea)
      ) {
        setFetchingActivities(true);
      } else {
        setFetchingActivities(false);
      }
    } else {
      setFetchingActivities(false);
    }
  }, [currentSelectedSubjectarea, activities]);

  const statusAndTypeTotals = useTaskFilterSummary(activeFilters.certificates);

  if (menuListLoading)
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  return (
    <>
      <RiskContainer>
        <RequirementsMeatballMenuV1 />
      </RiskContainer>
      {menuList && menuList.subjectAreaDocuments && menuList.subjectAreaDocuments.length > 0 && (
        <>
          <TaskFiltersV1
            onFiltersChanged={onFiltersChanged}
            activeFilters={activeFilters}
            statusAndTypeTotals={{ ...statusAndTypeTotals }}
            refreshFiltersAndSelectedActivities={refreshFiltersAndSelectedActivities}
            availableVesselCertificates={availableVesselCertificates}
            availableIntervalsAndInspectionTypes={availableIntervalsAndInspectionTypes}
          />
          <ListContainer>
            <ListMenuContainer>
              <div ref={ref}>
                <ListMenu
                  onMenuItemClick={e => onMenuItemClick(e)}
                  defaultSelected={currentSelectedSubjectarea}
                  activeFilters={activeFilters}
                  availableVesselCertificates={availableVesselCertificates}
                  availableIntervalsAndInspectionTypes={availableIntervalsAndInspectionTypes}
                  onFiltersChanged={onFiltersChanged}
                />
              </div>
            </ListMenuContainer>
            {((filteredActivities && filteredActivities.length > 0) || activitiesLoading) && (
              <ActivityList
                height={height}
                loading={activitiesLoading || fetchingActivities}
                currentSelectedSubjectarea={currentSelectedSubjectarea}
                requirementsListTabEnabled={requirementsListTabEnabled}
                removeFilterCallback={resetStatusAndCertificateFilters}
                filteredActivities={filteredActivities}
              />
            )}
            {!activitiesLoading && (!filteredActivities || filteredActivities.length === 0) && (
              <NoActivityContainer>
                <NoActivitySelected />
                <NoActivitySelectedHeader>
                  {intl.formatMessage({ id: 'activityadmin.no.activity.selected' })}
                </NoActivitySelectedHeader>
                <NoActivitySelectedText>
                  {intl.formatMessage({ id: 'activityadmin.msg.select.activity' })}
                </NoActivitySelectedText>
              </NoActivityContainer>
            )}
          </ListContainer>
        </>
      )}
    </>
  );
};

export default ActivitySection;

const ListMenuContainer = styled.div`
  width: 44rem;
  margin-right: 4rem;
`;

const RiskContainer = styled.div`
  display: flex;
  justify-content: end;
`;

const ListContainer = styled.div`
  display: flex;
`;

const LoaderContainer = styled.div`
  grid-area: 3 / 7;
`;

const NoActivityContainer = styled.div`
  grid-area: tasks;
  text-align: center;
  flex-grow: 1;
`;

const NoActivitySelectedText = styled.div`
  display flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  font-family: ${({ theme }) => theme.fonts.stfLight};
`;

const NoActivitySelectedHeader = styled.div`
  display flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  font-family: ${({ theme }) => theme.fonts.stfBook};
`;
