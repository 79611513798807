import { useState, useEffect } from 'react';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { SdirBreadcrumb } from '@sdir/sds';
import { useIntl } from 'react-intl';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';

import { Routes, vesselTabOptions } from '../Templates/Routes/constants';
import { constructionNoticeApi, projectApi } from '../../httpclient';
import { ActivityDetailType } from '../../types/enums';

interface Crumb {
  text: string;
  path: string;
}

const BreadCrumbNavigation = () => {
  const [showBreadCrumb, setShowBreadcrumb] = useState(false);
  const [crumbs, setCrumbs] = useState<Crumb[]>([]);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const intl = useIntl();
  const uidRegExp = new RegExp(/^[0-9a-f]+-[0-9a-f]+-[0-9a-f]+-[0-9a-f]+-[0-9a-f]+$/i);

  const { result: project, callApi: getProject } = useApi({
    apiFunction: projectuid => projectApi.v1ProjectGetprojectbyidProjectIdGet(projectuid)
  });

  const { result: constructionNotice, callApi: getConstructionNotice } = useApi({
    apiFunction: constructionNoticeUid =>
      constructionNoticeApi.v3ConstructionnoticeGetconstructionnoticev3IdGet(constructionNoticeUid)
  });

  const getCrumbLabel = path => {
    if (Object.values(ActivityDetailType).includes(path))
      return intl.formatMessage({ id: `breadcrumb.${path}` });

    switch (path) {
      case 'et':
        return intl.formatMessage({ id: 'breadcrumb.home' });
      case 'vessel':
        return project ? project.name : intl.formatMessage({ id: 'breadcrumb.vessel' });
      case 'managecertificate':
        return intl.formatMessage({ id: 'breadcrumb.managecertificate' });
      case 'newactivity':
        return intl.formatMessage({ id: 'breadcrumb.newactivity' });
      case 'construction':
        return intl.formatMessage({ id: 'breadcrumb.construction' });
      case 'riskassessment':
        return intl.formatMessage({ id: 'breadcrumb.riskAssessment' });
      case 'constructionnoticereview':
        return `${intl.formatMessage({ id: 'constructionnoticereview.header' })}${
          constructionNotice
            ? ` ${constructionNotice?.projectInformation?.constructionNumber} ${constructionNotice?.projectInformation?.constructionShipyard?.name}`
            : ''
        } `;
      default:
        return path;
    }
  };

  const createPath = (path, pathList) => {
    let workspaceTab = vesselTabOptions.activitylist;

    switch (path) {
      case 'vessel':
        if (pathList.includes('activitydetails')) {
          return generatePath(Routes.vesselWorkspaceScrollTo, {
            tab: vesselTabOptions.activitylist,
            projectuid: pathList[2],
            scrollToId: pathList[5]
          });
        }

        if (pathList.includes('managecertificate')) {
          workspaceTab = vesselTabOptions.certificate;
        }

        return `/et/vessel/${pathList[2]}/${workspaceTab}`;
      default:
        return `/${path}`;
    }
  };

  const filterPath = path => {
    if (Object.values(vesselTabOptions).includes(path)) {
      return false;
    }

    if (path === 'activitydetails' || uidRegExp.test(path) || !isNaN(path)) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    const insideFres = pathname.startsWith('/et');
    const pathList = pathname.substring(1, pathname.length).split('/');
    const pathsWithHiddenBreadCrumb = ['requirementdetail'];
    const hideBreadCrumbOnCurrentPage = pathsWithHiddenBreadCrumb.some(p => pathname.includes(p));

    if (hideBreadCrumbOnCurrentPage) {
      setShowBreadcrumb(false);
    } else {
      if (showBreadCrumb) {
        if (!insideFres || pathname === '/et') {
          setShowBreadcrumb(false);
        }
      }

      if (!showBreadCrumb) {
        if (insideFres && pathname !== '/et') {
          setShowBreadcrumb(true);
        }
      }
    }

    if (insideFres) {
      const filteredPathList = pathList.filter(p => filterPath(p));

      setCrumbs(
        filteredPathList.map(p => ({ text: getCrumbLabel(p), path: createPath(p, pathList) }))
      );

      if (pathname.includes('/vessel/')) {
        const projectuid = pathList[2];

        if (uidRegExp.test(projectuid) && (!project || project.uid !== projectuid)) {
          getProject(projectuid);
        }
      }
      if (pathname.includes('/constructionnoticereview/')) {
        const constructionnoticeuid = pathList[2];

        if (
          uidRegExp.test(constructionnoticeuid) &&
          (!constructionNotice || constructionNotice.id !== constructionnoticeuid)
        ) {
          getConstructionNotice(constructionnoticeuid);
        }
      }
    }
  }, [pathname, project, constructionNotice]);

  const navigateBreadCrumb = crumb => {
    navigate(crumb.path);
  };

  return showBreadCrumb ? (
    <Container>
      <SdirBreadcrumb
        separator=">"
        onBreadClicked={crumb => navigateBreadCrumb(crumb)}
        breads={crumbs}
        showHomeIcon={false}
      />
    </Container>
  ) : (
    <div />
  );
};

export default BreadCrumbNavigation;

const Container = styled.div`
  width: 100%;
  max-width: 188rem;
  padding: 5rem 0 0;
  margin: 0 auto;

  button {
    padding: 0;
  }
`;
