import { combineReducers } from 'redux';
import { GetProjectActivitiesOverviewV3Result } from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { User, Vessel, VesselBuildingProject } from '../../types/index.d';
import {
  SET_VESSEL,
  SET_CURRENT_REQUIREMENT,
  SET_VESSEL_BUILDING_PROJECT,
  SET_HISTORY,
  SET_ACTIVITIES_OVERVIEW,
  SET_PROJECT_UID,
  UPDATE_ACTIVITY_STATUS_COMPLETED,
  UPDATE_ACTIVITY_STATUS,
  SET_PLANNED_VESSEL_CERTIFICATES,
  SET_SPECIFICATION_OPTIONS,
  SET_ET_CASEWORKERS,
  SET_ET_CASEWORKERS_LOADING,
  SET_VESSEL_CERTIFICATES,
  SET_VESSEL_CARD_DATA,
  SET_VESSEL_CARD_DATA_LOADING
} from '../constants/action-types';
import { AppState } from '../constants/types';

export const initialState: AppState = {
  user: {} as User,
  vessel: {} as Vessel,
  userPermissions: [] as string[],
  currentRequirement: null,
  activitiesOverview: {} as GetProjectActivitiesOverviewV3Result,
  activityStatusChanged: undefined,
  plannedVesselCertificates: undefined,
  vesselCertificates: undefined,
  vesselSpecificationOptions: undefined,
  projectUid: undefined,
  vesselCardData: undefined,
  vesselCardDataLoading: false,
  etCaseWorkers: undefined,
  etCaseWorkersLoading: false,
  vesselBuildingProject: {} as VesselBuildingProject,
  loggs: '' as string,
  signee: '' as string,
  apiError: '',
  apiSuccess: ''
};

const updateActivityStatus = (
  activitiesOverview: GetProjectActivitiesOverviewV3Result | undefined,
  payload
) => {
  activitiesOverview?.subjectAreaDocuments?.forEach(activityOrActivityGroupOverview => {
    activityOrActivityGroupOverview?.activityOrActivityGroupOverview?.forEach(
      activityOrActivityGroup => {
        if (activityOrActivityGroup.activityDetail?.uid === payload.activityUid) {
          const lastEvent = payload.events?.length
            ? payload.events[payload.events.length - 1]
            : null;
          if (activityOrActivityGroup.activityDetail && lastEvent)
            activityOrActivityGroup.activityDetail.lastEvent = lastEvent;
        } else if (activityOrActivityGroup.documentActivitiesOverview?.activityOverview) {
          const lastEvent = payload.events.length
            ? payload.events[payload.events.length - 1]
            : null;
          activityOrActivityGroup.documentActivitiesOverview.activityOverview.forEach(activity => {
            if (activity.uid === payload.activityUid && lastEvent) {
              activity.lastEvent = lastEvent;
            }
          });
        }
      }
    );
  });
  return activitiesOverview;
};

function appStateReducer(state: AppState = initialState, action: { type: string; payload: any }) {
  switch (action.type) {
    case SET_VESSEL:
      return { ...state, vessel: action.payload };
    case SET_CURRENT_REQUIREMENT:
      return { ...state, currentRequirement: action.payload };
    case SET_VESSEL_BUILDING_PROJECT:
      return { ...state, vesselBuildingProject: action.payload };
    case SET_HISTORY:
      return { ...state, loggs: action.payload };
    case SET_ACTIVITIES_OVERVIEW:
      return { ...state, activitiesOverview: action.payload };
    case UPDATE_ACTIVITY_STATUS:
      return {
        ...state,
        activitiesOverview: {
          ...updateActivityStatus(state.activitiesOverview, action.payload)
        },
        activityStatusChanged: action.payload
      };
    case UPDATE_ACTIVITY_STATUS_COMPLETED:
      return {
        ...state,
        activityStatusChanged: undefined
      };
    case SET_VESSEL_CARD_DATA_LOADING:
      return {
        ...state,
        vesselCardDataLoading: action.payload
      };
    case SET_VESSEL_CARD_DATA:
      return {
        ...state,
        vesselCardData: action.payload
      };
    case SET_PLANNED_VESSEL_CERTIFICATES:
      return {
        ...state,
        plannedVesselCertificates: action.payload
      };
    case SET_VESSEL_CERTIFICATES:
      return {
        ...state,
        vesselCertificates: action.payload
      };
    case SET_SPECIFICATION_OPTIONS:
      return {
        ...state,
        vesselSpecificationOptions: action.payload
      };
    case SET_PROJECT_UID:
      return {
        ...state,
        projectUid: action.payload
      };
    case SET_ET_CASEWORKERS:
      return {
        ...state,
        etCaseWorkers: action.payload
      };
    case SET_ET_CASEWORKERS_LOADING:
      return {
        ...state,
        etCaseWorkersLoading: action.payload
      };

    default:
      return state;
  }
}
const allReducers = {
  appState: appStateReducer
};

const rootReducer = combineReducers(allReducers);

export default rootReducer;
