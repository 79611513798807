import { BlockBlobClient } from '@azure/storage-blob';
// eslint-disable-next-line max-len
import { CommonAddresseeServiceContractsV1ResponsesAttachmentResponse } from '@sdir/httpclient/lib/clients/core/commonaddressee';
import { addresseeAttachmentApi } from '../httpclient';

export const downloadAddresseeAttachment = async (
  legalEntityId: string,
  attachment: CommonAddresseeServiceContractsV1ResponsesAttachmentResponse
) => {
  const tokenResponse = await addresseeAttachmentApi.v1AttachmentGetdownloadurlCommandPost({
    attachmentUid: attachment.uid,
    blobName: attachment.blobName,
    legalEntityId: +legalEntityId
  });
  if (!tokenResponse.data?.blobUrl) {
    throw new Error('Unable to receive file upload token');
  }
  const blockBlobClient = new BlockBlobClient(tokenResponse.data.blobUrl);
  const downloadResponse = await blockBlobClient.download();
  const blob = await downloadResponse.blobBody;
  if (!blob) {
    throw new Error('Empty blob data');
  }
  return new File([blob], attachment.blobName || 'unknown.dat', { type: blob.type });
};
