export const SET_VESSEL = 'SET_VESSEL';
export const SET_GOLBAL_SEARCH = 'SET_GOLBAL_SEARCH';
export const SET_CURRENT_REQUIREMENT = 'SET_CURRENT_REQUIREMENT';
export const SET_VESSEL_BUILDING_PROJECT = 'SET_VESSEL_BUILDING_PROJECT';
export const SET_HISTORY = 'SET_HISTORY';
export const SET_ACTIVITIES_OVERVIEW = 'SET_ACTIVITIES_OVERVIEW';
export const UPDATE_ACTIVITY_STATUS = 'UPDATE_ACTIVITY_STATUS';
export const UPDATE_ACTIVITY_STATUS_COMPLETED = 'UPDATE_ACTIVITY_STATUS_COMPLETED';
export const SET_PLANNED_VESSEL_CERTIFICATES = 'SET_PLANNED_VESSEL_CERTIFICATES';
export const SET_SPECIFICATION_OPTIONS = 'SET_SPECIFICATION_OPTIONS';
export const SET_PROJECT_UID = 'SET_PROJECT_UID';
export const SET_ET_CASEWORKERS = 'SET_ET_CASEWORKERS';
export const SET_ET_CASEWORKERS_LOADING = 'SET_ET_CASEWORKERS_LOADING';
export const SET_VESSEL_CERTIFICATES = 'SET_VESSEL_CERTIFICATES';
export const SET_VESSEL_CARD_DATA_LOADING = 'SET_VESSEL_CARD_DATA_LOADING';
export const SET_VESSEL_CARD_DATA = 'SET_VESSEL_CARD_DATA';
