import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { TextLink, SdiTrash, SdiEdit } from '@sdir/sds';
import moment from 'moment';
import { SortableDataTable } from '@sdir/utilities/lib/components';
import { ProcessingState } from '@sdir/httpclient/lib/clients/aps/additionalcompetence';
import { courseEducationApi } from '../../httpclient';
import { DisplayDateFormat } from '../../helpers';
import { CourseRow, CourseTableColumns } from '../../helpers/TableHeaders';
import TableCardContainer from '../Atoms/TableCardContainer';
import { DeleteCourseModal } from './DeleteCourseModal';
import { EditCourseModal } from './EditCourseModalLink';

const CoursesAndEducationTableCard: React.FC<{ legalEntityId: string }> = ({ legalEntityId }) => {
  const intl = useIntl();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [uidToDelete, setUidToDelete] = useState('');
  const [editData, setEditData] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  const { result, loading, error, callApi } = useApi({
    apiFunction: courseEducationApi.v1CourseeducationCoursesQueryObjectPost,
    bind: courseEducationApi,
    callApiOnInit: false
  });

  const refreshCourses = (id: string) => {
    callApi({
      skip: 0,
      take: 100,
      legalEntityId: id,
      filter: ProcessingState.Approved
    });
  };

  const showModalDelete = useCallback(uid => {
    setUidToDelete(uid);
    setShowDeleteModal(true);
  }, []);

  const onSuccessDeleteCourse = useCallback(() => {
    setShowDeleteModal(false);
    refreshCourses(legalEntityId);
  }, [legalEntityId]);

  const onCancelDeleteModal = useCallback(() => {
    setShowDeleteModal(false);
  }, []);

  const showModalEdit = useCallback(row => {
    setEditData(row);
    setShowEditModal(true);
  }, []);

  const onSuccessEditCourse = useCallback(() => {
    setShowEditModal(false);
    refreshCourses(legalEntityId);
  }, [legalEntityId]);

  const onCancelEditModal = useCallback(() => {
    setShowEditModal(false);
  }, []);

  const tableData = useMemo(() => {
    if (!result) return [];

    return result.map<CourseRow>(val => ({
      organization: val.organization || val.organizationNumber,
      name: val.courseName,
      status: intl.formatMessage({ id: val.valid ? 'table.report.valid' : 'table.report.expired' }),
      date: DisplayDateFormat(val.issuedDate),
      dateValue: val.issuedDate ? moment(val.issuedDate) : null,
      expiry: DisplayDateFormat(val.expiryDate),
      expiryValue: val.expiryDate ? moment(val.expiryDate) : null,
      edit: (
        <TextLink
          data-testid="course-edit-button"
          icon={<SdiEdit size="s" />}
          text=""
          click={() => showModalEdit(val)}
        />
      ),
      remove: (
        <TextLink
          data-testid="course-delete-button"
          icon={<SdiTrash size="s" />}
          text=""
          click={() => showModalDelete(val.uid)}
        />
      )
    }));
  }, [intl, result]);

  useEffect(() => {
    if (legalEntityId) {
      refreshCourses(legalEntityId);
    }
  }, [legalEntityId]);

  return (
    <>
      <TableCardContainer
        title={intl.formatMessage({ id: 'datacard.courseandeducation.title' })}
        loading={loading}
        error={error}
        data={tableData}
      >
        <SortableDataTable columns={CourseTableColumns} data={tableData} sortableKeys="*" />
      </TableCardContainer>
      {showEditModal && (
        <EditCourseModal
          row={editData}
          display={showEditModal}
          onCancelClick={onCancelEditModal}
          onSuccessEdit={onSuccessEditCourse}
        />
      )}
      {showDeleteModal && (
        <DeleteCourseModal
          uid={uidToDelete}
          display={showDeleteModal}
          onCancelClick={onCancelDeleteModal}
          onSuccessDelete={onSuccessDeleteCourse}
        />
      )}
    </>
  );
};
export default CoursesAndEducationTableCard;
