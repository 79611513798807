/* eslint-disable prettier/prettier */
import React, { useEffect } from 'react';
import { Loader, SdirButton } from '@sdir/sds';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useNavigate, useLocation, useParams, generatePath } from 'react-router-dom';
import {
  ActivityOrActivityGroup,
  GetProjectActivitiesOverviewV3Result
} from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { useSelector } from 'react-redux';
import InspectionTaskCard from '../Molecules/ActivityCard/InspectionTaskCard';
// eslint-disable-next-line max-len
import DocumentControlActivityGroupCard from '../Molecules/ActivityCard/DocumentControlActivityGroupCard';
import { Routes } from '../Templates/Routes';
import { ToggleContent } from '../Atoms';
import { NewActivityButton } from '../../constants/permissions';

interface Props {
  loading: boolean;
  removeFilterCallback?: () => void;
  filteredActivities?: ActivityOrActivityGroup[];
  height: string;
  currentSelectedSubjectarea?: string;
  requirementsListTabEnabled?: boolean;
}

const ActivityList: React.FC<Props> = ({
  filteredActivities,
  loading,
  removeFilterCallback,
  height,
  currentSelectedSubjectarea,
  requirementsListTabEnabled
}) => {
  const { scrollToId, projectuid } = useParams() as { scrollToId?: string; projectuid?: string };
  const navigate = useNavigate();
  const location = useLocation();
  const intl = useIntl();
  const subjectAreaList: GetProjectActivitiesOverviewV3Result = useSelector(
    (state: any) => state.appState.activitiesOverview
  );

  useEffect(() => {
    /* Use optional scroll path param to scroll to activity when loaded */
    if (scrollToId && filteredActivities) {
      setTimeout(() => {
        const activityEl = document.getElementById(scrollToId);
        activityEl?.scrollIntoView({ behavior: 'smooth' });
        /* remove scroll path param after */
        const newpathname = location.pathname
          .split('/')
          .slice(0, -1)
          .join('/');
        navigate(newpathname, { replace: true });
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollToId, filteredActivities]);

  const handleNewActivityClick = () => {
    if (
      !localStorage.getItem('newActivityCurrentSubjectarea') &&
      subjectAreaList?.subjectAreaDocuments?.length
    ) {
      localStorage.setItem(
        'newActivityCurrentSubjectarea',
        currentSelectedSubjectarea || subjectAreaList.subjectAreaDocuments[0].subjectAreaUri || ''
      );
    }
    navigate(
      generatePath(Routes.newactivity, {
        projectuid
      })
    );
  };

  return loading ? (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  ) : (
    <>
      <Container height={height}>
        <ToggleContent toggleName={NewActivityButton}>
          <ButtonWrapper>
            <SdirButton
              text={intl.formatMessage({ id: 'documentcard.newactivitybuttonlabel' })}
              onClick={() => handleNewActivityClick()}
            />
          </ButtonWrapper>
        </ToggleContent>
        <Wrapper>
          {filteredActivities?.map(activity => {
            if (activity.documentControlActivityGroup) {
              return (
                <DocumentControlActivityGroupCard
                  key={activity.documentControlActivityGroup.documentUri}
                  documentControlActivityGroup={activity.documentControlActivityGroup}
                  requirementsListTabEnabled={requirementsListTabEnabled}
                  removeFilterCallback={removeFilterCallback}
                />
              );
            }
            if (activity.activity) {
              return (
                <div key={activity.activity.uid}>
                  <InspectionTaskCard
                    activity={activity.activity}
                    requirementsListTabEnabled={requirementsListTabEnabled}
                  />
                </div>
              );
            }
            return null;
          })}
        </Wrapper>
      </Container>
    </>
  );
};

const Container = styled.article<{ height: string }>`
  width: 100%;
  margin-bottom: 4rem;
  margin-left: 14rem;

  overflow-y: scroll;
  padding: 0 2rem;
  height: ${({ height }) => `calc(${height} - 14rem)`}; // minus margin top

  &::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background-color: ${({ theme }) => theme.colors.primary9};
    outline: ${({ theme }) => theme.colors.primary10};
  }

  &::-webkit-scrollbar {
    width: 1rem;
    height: 1rem;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgb(0 0 0 / 10%);
    border-radius: 6px;
    margin: 12px 0;
  }
`;

const LoaderContainer = styled.div`
  display: grid;
  justify-self: center;
  z-index: 1000;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  justify-content: center;
  grid-column-start: 6;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 3rem;
`;

export default React.memo(ActivityList);
