import { SdiCertificate, SdiEdit, SdirButton, SdirModal, TextLink, Tag } from '@sdir/sds';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import {
  ActivityCard,
  ActivityStatusLabel,
  TaskMetaCard,
  ToggleButton
} from '@sdir/blueprint.et/lib/components';
import {
  ActionType,
  ActivityStatus,
  ActivityWithRisk,
  EventType,
  GetActivityDetailsV2ResultEventsInner,
  ReactionCreatedDomainEvent
} from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { typeTag } from '@sdir/blueprint.et/lib/components/Organisms/ActivitiesOverviewMenu/ListMenu';
import { generatePath } from 'react-router-dom';
import ExemptionActionMessage from './ExemptionActionMessage';
import NotRelevantActionMessage from './NotRelevantActionMessage';
import ReactionDraftText from '../ReactionDraftText';
import TaskCardAccordion from './TaskCardAccordion';
import { Routes } from '../../Templates/Routes';
import { ActivityDetailType } from '../../../types/enums';

export enum ToggleState {
  Relevant,
  NotRelevant,
  Messages,
  None
}

const getLastEvent = (activity, events) =>
  events?.length
    ? {
        status: events[events.length - 1].status,
        eventType: events[events.length - 1].eventType
      }
    : { status: activity.lastEvent?.status, eventType: activity.lastEvent?.eventType };

const handleReactionClick = (navigate, projectuid, activityuid) => {
  navigate(
    generatePath(Routes.activityDetails, {
      projectuid,
      activityuid,
      type: ActivityDetailType.reaction
    })
  );
};

const handleApplicationClick = (navigate, projectuid, activityuid, type) => {
  navigate(
    generatePath(Routes.activityDetails, {
      projectuid,
      activityuid,
      type: type.toLowerCase()
    })
  );
};

const defaultButtons = (
  intl,
  isDisabled,
  isLoading,
  sendForApproval,
  navigate,
  projectuid,
  activityuid,
  handleToggleState
) => (
  <>
    <TextLink
      text={intl.formatMessage({ id: 'notrelevantcard.title' })}
      disabled={isLoading}
      click={() => handleToggleState(ToggleState.NotRelevant)}
    />
    <SdirButton
      type="default"
      disabled={isLoading}
      onClick={() => handleReactionClick(navigate, projectuid, activityuid)}
    >
      {intl.formatMessage({ id: 'vessel.activity.action.newreaction' })}
    </SdirButton>
    <SdirButton type="primary" disabled={isDisabled || isLoading} onClick={() => sendForApproval()}>
      {intl.formatMessage({ id: 'vessel.activity.action.accept' })}
    </SdirButton>
  </>
);

export const CloseReactionModal = ({ visible, loading, onCancel, onOk, events, activity }) => {
  const intl = useIntl();
  const reactionUid = events?.length
    ? (events[events.length - 1] as ReactionCreatedDomainEvent).reactionId
    : (activity.lastEvent as ReactionCreatedDomainEvent).reactionId;
  return (
    <SdirModal
      visible={visible}
      onCancel={onCancel}
      width="52rem"
      isFooter={false}
      closeButton={false}
    >
      <CloseReactionContent>
        <h1>{intl.formatMessage({ id: 'vessel.activity.action.closereaction' })}</h1>
        <p>{intl.formatMessage({ id: 'closereaction.modal.text' })}</p>

        <ReactionModalButtonContainer>
          <TextLink text={intl.formatMessage({ id: 'common.cancel' })} click={onCancel} />
          <SdirButton
            disabled={loading}
            text={intl.formatMessage({ id: 'vessel.activity.action.closereaction' })}
            type="primary"
            onClick={() => onOk(reactionUid)}
          />
        </ReactionModalButtonContainer>
      </CloseReactionContent>
    </SdirModal>
  );
};

const buttons = (
  intl,
  activity: ActivityWithRisk,
  events: GetActivityDetailsV2ResultEventsInner[] | null | undefined,
  reactionDraft,
  isLoading,
  closingReaction,
  setViewCloseReactionModal,
  isDisabled,
  sendForApproval,
  navigate,
  projectuid,
  handleToggleState
) => {
  const lastEvent = getLastEvent(activity, events);

  const reactionEvent: ReactionCreatedDomainEvent | null | undefined = events?.length
    ? events[events.length - 1]
    : activity.lastEvent;

  switch (lastEvent.eventType) {
    case EventType.Accepted:
      if (reactionDraft) {
        return (
          <SdirButton
            type="primary"
            disabled={isLoading}
            onClick={() => handleReactionClick(navigate, projectuid, activity.uid)}
          >
            {intl.formatMessage({ id: 'vessel.activity.action.opendraft' })}
          </SdirButton>
        );
      }
      return (
        <TextLinkWrapper>
          <TextLink
            text={intl.formatMessage({
              id: 'vessel.activity.action.changeassessment'
            })}
            icon={<SdiEdit size="s" height="1em" />}
            disabled={isLoading}
            onClick={() => handleReactionClick(navigate, projectuid, activity.uid)}
          />
        </TextLinkWrapper>
      );
    case EventType.ReactionCreated:
      return (
        <>
          <Info>
            {intl.formatMessage({
              id: `vessel.activity.reaction.sent.${reactionEvent?.reactionType?.toLowerCase()}`
            })}
          </Info>
          <TextLinkWrapper>
            <TextLink
              disabled={closingReaction}
              text={intl.formatMessage({
                id: 'vessel.activity.action.closereaction'
              })}
              onClick={() => setViewCloseReactionModal(true)}
            />
          </TextLinkWrapper>
          <TextLinkWrapper>
            <TextLink
              text={intl.formatMessage({
                id: 'vessel.activity.action.replacereaction'
              })}
              onClick={() => handleReactionClick(navigate, projectuid, activity.uid)}
            />
          </TextLinkWrapper>
        </>
      );
    case EventType.ReactionReplied:
      return (
        <SdirButton
          type="primary"
          disabled={isLoading}
          onClick={() => handleReactionClick(navigate, projectuid, activity.uid)}
        >
          {intl.formatMessage({ id: 'vessel.activity.action.evaluateresponse' })}
        </SdirButton>
      );
    case EventType.NotRelevantAccepted:
    case EventType.NotRelevantSet:
      return (
        <>
          <Info>
            {intl.formatMessage({
              id: `replytoapplication.infocard.info.relevant`
            })}
          </Info>
          <TextLinkWrapper>
            <TextLink
              text={intl.formatMessage({
                id: 'replytoapplication.infocard.action.relevant'
              })}
              onClick={() => handleToggleState(ToggleState.Relevant)}
            />
          </TextLinkWrapper>
        </>
      );
    case EventType.NotRelevantRequested:
      return (
        <SdirButton
          type="primary"
          disabled={isLoading}
          onClick={() =>
            handleApplicationClick(navigate, projectuid, activity.uid, ActionType.NotRelevant)
          }
        >
          {intl.formatMessage({
            id: 'activitycard.notrelevant.answer'
          })}
        </SdirButton>
      );
    case EventType.ExemptionRequested:
      return (
        <SdirButton
          type="primary"
          disabled={isLoading}
          onClick={() =>
            handleApplicationClick(navigate, projectuid, activity.uid, ActionType.Exemption)
          }
        >
          {intl.formatMessage({
            id: 'activitycard.exemption.answer'
          })}
        </SdirButton>
      );
    default:
      if (reactionDraft) {
        return (
          <SdirButton
            type="primary"
            disabled={isLoading}
            onClick={() => handleReactionClick(navigate, projectuid, activity.uid)}
          >
            {intl.formatMessage({ id: 'vessel.activity.action.opendraft' })}
          </SdirButton>
        );
      }
      return defaultButtons(
        intl,
        isDisabled,
        isLoading,
        sendForApproval,
        navigate,
        projectuid,
        activity.uid,
        handleToggleState
      );
  }
};

export const activityCardLeftColumn = (
  intl,
  activity: ActivityWithRisk,
  events: GetActivityDetailsV2ResultEventsInner[] | null | undefined,
  toggleState,
  getActivityDetails,
  handleToggleState,
  isLast,
  tagList: typeTag[] = []
) => {
  const lastEvent = getLastEvent(activity, events);

  return (
    <LeftColumn>
      <LeftColumnContent>
        <ActivityStatusLabel status={lastEvent.status} eventType={lastEvent.eventType} />
        <Key>{intl.formatMessage({ id: 'filecard.responsible' })}</Key>
        <Val>{activity.responsible ? activity.responsible : '-'}</Val>
        {!isLast && <Line />}

        <Key>
          {intl.formatMessage({
            id: 'activitycard.header.title.mustberenewed'
          })}
        </Key>
        <Val>{activity.mustBeRenewedBy ? new Date(activity.mustBeRenewedBy).toString() : '-'}</Val>
        {tagList.length > 0 && (
          <>
            <Key>
              <SdiCertificate size="s" style={{ margin: '0.3rem 1rem 0rem 0' }} />
              {intl.formatMessage({ id: 'common.incertificate.label' })}
            </Key>
            <TagList>
              {tagList.map(tag => (
                <Tag size="s" key={tag.value} label={tag.label} value={tag.value} />
              ))}
            </TagList>
          </>
        )}
      </LeftColumnContent>
      <ToggleButton
        isToggled={toggleState === ToggleState.Messages}
        onClick={() => {
          if (toggleState !== ToggleState.Messages) {
            getActivityDetails();
          }
          handleToggleState(ToggleState.Messages);
        }}
        text={intl.formatMessage({ id: 'activitycard.togglebutton.log' })}
      />
    </LeftColumn>
  );
};

export const activityCardRightColumn = (
  intl,
  activity: ActivityWithRisk,
  events: GetActivityDetailsV2ResultEventsInner[] | null | undefined,
  activityMeatballMenuItems,
  downloadByFilename,
  reactionDraft,
  icon,
  approvalLoading,
  activityDetailsLoading,
  closingReaction,
  setViewCloseReactionModal,
  sendForApproval,
  navigate,
  projectuid,
  handleToggleState,
  getLawText,
  getLawTextAct,
  regulationTextState,
  regulationTextDispatch
) => {
  const lastEvent = getLastEvent(activity, events);

  const isDisabled =
    lastEvent.status === ActivityStatus.Accepted || lastEvent.status === ActivityStatus.NotRelevant;

  const isLoading = approvalLoading || activityDetailsLoading;

  return (
    <RightColumn>
      <ActivityCard
        internalComment={activity.internalComment}
        icon={icon}
        activityMeatballMenuItems={activityMeatballMenuItems}
        type={intl.formatMessage({
          id: `checkpointtype.${activity.checkpointType?.split('#')[1]?.toLowerCase()}`
        })}
        title={activity.name}
        description={activity.description}
        elirefs={activity.requirementEliRefs}
        displayPursuantToButton={Boolean(activity.requirementEliRefs?.length)}
        getLawText={getLawText}
        getLawTextAct={getLawTextAct}
        regulationTextState={regulationTextState}
        regulationTextDispatch={regulationTextDispatch}
      />
      {lastEvent.eventType === EventType.ExemptionRequested && (
        <ExemptionActionMessage
          activity={activity}
          events={events}
          downloadFile={downloadByFilename}
        />
      )}
      {lastEvent.eventType === EventType.NotRelevantRequested && (
        <NotRelevantActionMessage activity={activity} events={events} />
      )}
      {reactionDraft && (
        <ReactionDraftText reactionType={reactionDraft.type} reactionText={reactionDraft.text} />
      )}
      <ActivityCardButtonContainer>
        {buttons(
          intl,
          activity,
          events,
          reactionDraft,
          isLoading,
          closingReaction,
          setViewCloseReactionModal,
          isDisabled,
          sendForApproval,
          navigate,
          projectuid,
          handleToggleState
        )}
      </ActivityCardButtonContainer>
    </RightColumn>
  );
};

export const taskCardAccordion = (
  activityDetails,
  activityDetailsLoading,
  downloadByFilename,
  numberOfMessages,
  toggleState,
  activityUid,
  projectuid,
  submitNotRelevant,
  submitRelevant,
  setToggleState,
  notRelevantLoading,
  relevantLoading,
  fetchMessages
) => (
  <TaskCardAccordion
    activityDetails={activityDetails}
    activityDetailsLoading={activityDetailsLoading}
    downloadByFilename={downloadByFilename}
    numberOfMessages={numberOfMessages}
    toggleState={toggleState}
    activityUid={activityUid}
    projectuid={projectuid}
    notRelevantLoading={notRelevantLoading}
    relevantLoading={relevantLoading}
    setToggleState={setToggleState}
    submitNotRelevant={submitNotRelevant}
    submitRelevant={submitRelevant}
    fetchMessages={fetchMessages}
  />
);

export const taskMetaCard = (
  activityUid,
  activityMetadataLoading,
  activityMetadata,
  getActivityMetadata,
  setViewMetadata
) => (
  <TaskMetaCard
    activityUid={activityUid}
    activityMetadataLoading={activityMetadataLoading}
    activityMetadata={activityMetadata}
    getActivityDetailCallback={getActivityMetadata}
    onClose={() => {
      setViewMetadata(false);
    }}
  />
);

const ReactionModalButtonContainer = styled.div`
  justify-content: space-between;
  display: flex;
  margin: 0 1rem;
`;

const ActivityCardButtonContainer = styled.div`
  margin-top: 4rem;
  display: flex;
  justify-content: flex-end;

  button {
    margin-left: 4rem;
  }
`;

const Info = styled.span``;

const CloseReactionContent = styled.div`
  display: flex;
  flex-direction: column;

  * {
    font-family: ${({ theme }) => theme.fonts.stfBook};
    font-size: 2rem;
  }

  h1 {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 3rem;
    margin-bottom: 3rem;
  }

  p {
    color: ${({ theme }) => theme.colors.font.text};
    margin-bottom: 6rem;
    margin-top: 0;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 20rem;
  justify-content: space-between;
`;

const LeftColumnContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const Key = styled.span`
  font-family: ${({ theme }) => theme.fonts.stfBook};
  color: ${({ theme }) => theme.colors.secondary.grey1};
  font-size: 1.75rem;
  line-height: 1;
  display: flex;
  align-items: center;
`;

const Val = styled.span`
  font-family: ${({ theme }) => theme.fonts.stfSemibold};
  color: ${({ theme }) => theme.colors.font.text};
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const Line = styled.div`
  border-bottom: 2px dashed ${({ theme }) => theme.colors.secondary.grey1};
  position: relative;
  right: 7.3rem;
  z-index: -1;
  width: 3.3rem;
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-content: space-between;
`;

export const AccordionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TaskCard = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 3px 3px #00000029;
  margin-bottom: 2rem;
  padding: 3rem 3rem 6rem 4rem;
  display: flex;
  justify-content: space-between;
  z-index: 2;
`;

export const TagList = styled.span`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 0.5rem 0 0.5rem 0;
  align-items: center;
`;

const TextLinkWrapper = styled.div`
  height: 6rem;
`;
