import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { CertificateStatus } from '@sdir/httpclient/lib/clients/aps/personalcertificate';
import { SortableDataTable } from '@sdir/utilities/lib/components';
import moment from 'moment';
import { Link } from 'react-router-dom';
// import localization from '../../localization/localization';
import { personalCertificateApi } from '../../httpclient';
import { DisplayDateFormat, GetCertificateName } from '../../helpers';
import { PersonalCertificateColums, PersonalCertificateRow } from '../../helpers/TableHeaders';
import TableCardContainer from '../Atoms/TableCardContainer';

const RadioCertificates: string[] = ['GOC', 'LRC', 'ROC', 'SRC'];

// eslint-disable-next-line max-len
const getCertificatesApi = personalCertificateApi?.v1PersonalcertificateGetpersonalcertificatebylegalentityidwitherrorsQueryPost.bind(
  personalCertificateApi
);

const PersonalCertificateTable: React.FC<{ legalEntityId: string }> = ({ legalEntityId }) => {
  const intl = useIntl();
  const [showAll, setShowAll] = useState<boolean>(false);

  const { result, loading, error, callApi } = useApi({
    apiFunction: getCertificatesApi,
    callApiOnInit: false
  });

  const toggleAll = useCallback(() => {
    setShowAll(!showAll);
  }, [showAll]);

  const refresh = useCallback(() => {
    callApi({
      skip: 0,
      take: 100,
      legalEntityId
    });
  }, [callApi, legalEntityId]);

  useEffect(() => {
    if (legalEntityId) {
      refresh();
    }
  }, [callApi, legalEntityId, refresh]);

  const tableData = useMemo(() => {
    if (!result) return [];
    const lang = intl.locale === 'en' ? 'en' : 'no';
    const validStatuses: CertificateStatus[] = [
      CertificateStatus.Valid,
      CertificateStatus.Inactive
    ];
    const filtered = showAll
      ? result?.personalCertificates || []
      : (result?.personalCertificates || []).filter(
          i => i.status && validStatuses.includes(i.status)
        );
    return filtered.map<PersonalCertificateRow>(item => {
      const statusText = item.status
        ? intl.formatMessage({ id: `aps.certificate.status.${item.status}` })
        : item.status;
      const isRadioCertificate = RadioCertificates.includes(item?.certificateType || '');
      return {
        certificateType: GetCertificateName(lang, item) || item.certificateType || '',
        issuedDate: DisplayDateFormat(item.issuedDate),
        issuedDateValue: item.issuedDate ? moment(item.issuedDate) : null,
        validToDate: DisplayDateFormat(item.expiryDate),
        validToDateValue: item.expiryDate ? moment(item.expiryDate) : null,
        certificateStatus: statusText,
        validBtn: isRadioCertificate ? (
          <></>
        ) : (
          <>
            <Link
              style={{ textDecoration: 'underline', color: 'black' }}
              to={`/certificate/${legalEntityId}/${item?.uid}`}
            >
              {intl.formatMessage({ id: 'aps.certificate.link' })}
            </Link>
          </>
        )
      };
    });
    // Se sertifikat
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result, showAll]);

  return (
    <TableCardContainer
      title={intl.formatMessage({ id: 'datacard.certificates.title' })}
      loading={loading}
      error={error}
      data={tableData}
      linkText={
        showAll
          ? intl.formatMessage({ id: 'datacard.certificates.showonlyvalid' })
          : intl.formatMessage({ id: 'datacard.certificates.showall' })
      }
      expandable
      onExpandClicked={toggleAll}
    >
      <SortableDataTable
        columns={PersonalCertificateColums}
        data={tableData}
        sortableKeys={['certificateType', 'certificateStatus', 'issuedDate', 'validToDate']}
      />
    </TableCardContainer>
  );
};

export default PersonalCertificateTable;
